import { useState, useEffect, ReactNode } from "react";
import { useAuth } from "../../context/AuthContext";
import Button from "../common/Button";
import Toggle from "./Toggle";
import Dropdown from "./Dropdown";
import { toast } from "react-hot-toast";
import axios from "axios";
import TextArea from "./TextArea";
import {
  PreferenceDateFormatType,
  PreferencePerspectiveType,
  PreferenceTimeFormatType,
  SubjectReferenceStyleType,
  ReportPreferences,
  NewReportPreferences,
  reportPreferences,
} from "../../db/schema";
import Loading from "../common/Loading";
import DOMPurify from "dompurify";

// Add sanitization utilities
const sanitizeInput = (value: string): string => {
  return DOMPurify.sanitize(value);
};

const sanitizeTextArea = (value: string): string => {
  return DOMPurify.sanitize(value, {
    ALLOWED_TAGS: [], // Strip all HTML tags
    ALLOWED_ATTR: [], // Strip all attributes
  });
};

const Preferences = () => {
  const { user } = useAuth();

  // Add these interfaces
  interface PreferenceField {
    label: string;
    description: ReactNode;
    type: "toggle" | "dropdown" | "textarea";
    options?: { value: string; label: string }[];
    placeholder?: string;
    maxLength?: number;
  }

  interface PreferenceMapping {
    [key: string]: PreferenceField;
  }

  // Add the configuration object
  const PREFERENCE_FIELDS: PreferenceMapping = {
    [reportPreferences.badge_number.name]: {
      label: "Include Badge Number",
      description: (
        <p>
          Uses badge # set on account. "I, {user?.name}{" "}
          <b>(Badge {user?.badgeID || "#1118"})</b>, responded to the area of …"
        </p>
      ),
      type: "toggle",
    },
    [reportPreferences.name.name]: {
      label: "Include Name",
      description: (
        <p>
          Uses name set on account. "I, <b>{user?.name}</b>, responded to the
          area of …" vs "I responded to the area of …"
        </p>
      ),
      type: "toggle",
    },
    // [reportPreferences.top_sheet_information.name]: {
    //   label: "Include Top Sheet Information",
    //   description: <p>Include standard report header information.</p>,
    //   type: "toggle",
    // },
    [reportPreferences.topic_headings.name]: {
      label: "Include Topic Headings",
      description: (
        <p>
          Organizes your report into bite-sized sections organized with topic
          headings to easily scan for information.
        </p>
      ),
      type: "toggle",
    },
    [reportPreferences.abbreviate_rank.name]: {
      label: "Abbreviate Rank",
      description: (
        <p>
          Abbreviate rank when addressing other officers ("Ofc." vs "Officer")
        </p>
      ),
      type: "toggle",
    },
    [reportPreferences.day_of_week.name]: {
      label: "Include Day of Week",
      description: (
        <p>
          Include the day of week in date references. "On <b>Monday</b>, April
          1st, 2024..."
        </p>
      ),
      type: "toggle",
    },
    [reportPreferences.date_format.name]: {
      label: "Date Format",
      description: <p>Choose how dates should be formatted in the report.</p>,
      type: "dropdown",
      options: [
        {
          value: PreferenceDateFormatType.enum["MMMM DD YYYY"],
          label: "January 31, 2024",
        },
        {
          value: PreferenceDateFormatType.enum["MM/DD/YYYY"],
          label: "1/31/2024",
        },
        {
          value: PreferenceDateFormatType.enum["DD/MM/YYYY"],
          label: "31/1/2024",
        },
      ],
    },
    [reportPreferences.time_format.name]: {
      label: "Time Format",
      description: <p>Choose between Military or 12-hour time format.</p>,
      type: "dropdown",
      options: [
        {
          value: PreferenceTimeFormatType.enum.military_time,
          label: "Military (2100 hours)",
        },
        {
          value: PreferenceTimeFormatType.enum.standard_time,
          label: "12-hour (9:00 PM)",
        },
      ],
    },
    [reportPreferences.perspective.name]: {
      label: "Narrative Perspective",
      description: <p>Choose the point of view for the narrative.</p>,
      type: "dropdown",
      options: [
        {
          value: PreferencePerspectiveType.enum.first_person,
          label: "First Person",
        },
        {
          value: PreferencePerspectiveType.enum.third_person,
          label: "Third Person",
        },
      ],
    },
    [reportPreferences.subject_reference_style.name]: {
      label: "Subject Reference Style",
      description: (
        <p>
          Choose how to refer to subjects after first mention. ("The victim" vs.
          "John Doe" vs. "Doe")
        </p>
      ),
      type: "dropdown",
      options: [
        {
          value: SubjectReferenceStyleType.enum.role_after_first_mention,
          label: "Role After First Mention",
        },
        {
          value: SubjectReferenceStyleType.enum.full_name_throughout,
          label: "Full Name Throughout",
        },
        {
          value: SubjectReferenceStyleType.enum.last_name_after_first_mention,
          label: "Last Name After First Mention",
        },
      ],
    },
    [reportPreferences.header.name]: {
      label: "Custom Header",
      description: (
        <p>Add a boilerplate header to the beginning of all reports.</p>
      ),
      type: "textarea",
      placeholder: `(ex: Reporting Officer: ${user?.name})`,
    },
    [reportPreferences.footer.name]: {
      label: "Custom Footer",
      description: <p>Add a boilerplate footer to the end all reports.</p>,
      type: "textarea",
      placeholder: "(ex: My body worn camera was activated)",
    },
    [reportPreferences.additional_prompt.name]: {
      label: "Additional Instructions",
      description: (
        <p>
          Add additional instructions that expand upon or modify existing
          configuration options. If conflicting, custom instructions added here
          will override default settings for report generation.
        </p>
      ),
      type: "textarea",
      maxLength: 500,
    },
  };

  function Preferences() {
    const [preferences, setPreferences] = useState<ReportPreferences | null>(
      null
    );
    const [tempPreferences, setTempPreferences] = useState<
      Partial<NewReportPreferences>
    >({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
      const fetchPreferences = async () => {
        try {
          const response = await axios.get<{ preferences: ReportPreferences }>(
            "/api/settings/report-preferences"
          );
          const prefs = response.data.preferences;
          setPreferences(prefs);
          setTempPreferences(prefs);
        } catch (error) {
          toast.error("Failed to load preferences");
        } finally {
          setIsLoading(false);
        }
      };

      fetchPreferences();
    }, []);

    const handlePreferenceChange = (key: string, value: any) => {
      let sanitizedValue = value;
      if (typeof value === "string") {
        sanitizedValue =
          PREFERENCE_FIELDS[key].type === "textarea"
            ? sanitizeTextArea(value)
            : sanitizeInput(value);
      }
      setTempPreferences((prev) => ({ ...prev, [key]: sanitizedValue }));
    };

    const handleSave = async () => {
      setIsSaving(true);
      try {
        const response = await axios.patch<{ preferences: ReportPreferences }>(
          "/api/settings/report-preferences",
          tempPreferences
        );
        setPreferences(response.data.preferences);
        toast.success("Preferences saved successfully");
      } catch (error) {
        toast.error("Failed to save preferences");
      } finally {
        setIsSaving(false);
      }
    };

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="grid gap-y-2">
        {Object.entries(PREFERENCE_FIELDS).map(([key, field]) => {
          switch (field.type) {
            case "toggle":
              return (
                <Toggle
                  key={key}
                  label={field.label}
                  description={field.description}
                  checked={tempPreferences[key]}
                  onChange={(e) =>
                    handlePreferenceChange(key, e.target.checked)
                  }
                />
              );
            case "dropdown":
              return (
                <Dropdown
                  key={key}
                  label={field.label}
                  description={field.description}
                  options={field.options || []}
                  value={tempPreferences[key]}
                  onChange={(value) => handlePreferenceChange(key, value)}
                />
              );
            case "textarea":
              return (
                <TextArea
                  key={key}
                  label={field.label}
                  description={field.description}
                  value={tempPreferences[key] || ""}
                  onChange={(e) => handlePreferenceChange(key, e.target.value)}
                  rows={4}
                  placeholder={field.placeholder}
                  maxLength={field.maxLength}
                />
              );
            default:
              return null;
          }
        })}
        <Button
          variant="primary"
          className="w-full sticky bottom-0 py-4"
          onClick={handleSave}
          disabled={isSaving}
        >
          Save
        </Button>
      </div>
    );
  }

  return (
    <div className="flex-1 p-6 flex flex-col w-full prose items-center overflow-y-auto mt-8 md:mt-0">
      <div className="max-w-5xl mt-8 w-full">
        <h1 className="text-4xl font-semibold text-black-400 capitalize w-full text-center">
          Report Preferences
        </h1>
        <Preferences />
      </div>
    </div>
  );
};

export default Preferences;
