import { ReactNode } from "react";
import Tooltip from "./Tooltip";
import { useIsMobile } from "../../hooks/useIsMobile";

export default function TooltipIcon({
  description,
  className,
  size,
}: {
  description: string | ReactNode;
  className?: string;
  size?: "sm" | "md";
}) {
  const isMobile = useIsMobile();

  function iconSize() {
    switch (size) {
      case "sm":
        return "18px";
      case "md":
        return "22px";
      default:
        return "22px";
    }
  }

  const widthAndHeight = iconSize();

  return (
    <Tooltip className={className} placement="top" overlay={description}>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 20 20"
        aria-hidden="true"
        height={isMobile ? "16px" : widthAndHeight}
        width={isMobile ? "16px" : widthAndHeight}
        xmlns="http://www.w3.org/2000/svg"
        className="ml-1 cursor-pointer text-gray-800"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </Tooltip>
  );
}
