import { InputHTMLAttributes, ReactNode } from "react";
import cn from "classnames";
import TooltipIcon from "../common/TooltipIcon";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  variant?: "sm" | "md";
  description: string | ReactNode;
  id?: string;
}

export default function Toggle({
  className,
  label,
  variant = "md",
  description,
  id,
  ...props
}: InputProps) {
  const uniqueId = id || `toggle-${label.toLowerCase().replace(/\s+/g, "-")}`;

  return (
    <fieldset className="form-control">
      <div className="label p-0 min-h-10">
        <span className="flex items-center">
          <span className="text-gray-800 font-medium text-xs md:text-base">
            {label}
          </span>
          <TooltipIcon description={description} />
        </span>
        <input
          id={uniqueId}
          className={cn(
            "toggle toggle-md border-2 border-gray-400 bg-gray-400",
            "checked:border-blue-500 checked:bg-blue-500",
            "[&:not(:checked)]:bg-gray-400",
            "[&:after]:!bg-white",
            className
          )}
          type="checkbox"
          aria-label={label}
          {...props}
        />
      </div>
    </fieldset>
  );
}
