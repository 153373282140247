import { useNavigate } from "react-router-dom";
import Loading from "./common/Loading";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center space-y-6">
        <div className="max-w-80">
          <Loading />
        </div>
      </div>
    </div>
  );
}

export default App;
