import React, { useMemo } from "react";
import cn from "classnames";
import moment from "moment";
import { Case as CaseType } from "../../db/schema";

interface CaseProps extends CaseType {
  selected?: boolean;
  handleSelectedCase: (caseId: string) => void;
}

function extractTitle(content: string): string {
  const match = content.match(/<h1[^>]*>(.*?)<\/h1>/);
  return match ? match[1] : "New Note";
}

function stripHtmlTags(html: string): string {
  return html.replace(/<h1.*?<\/h1>|<[^>]*>/g, "");
}

function Case({
  id,
  selected,
  content,
  updatedAt,
  handleSelectedCase,
  status,
}: CaseProps) {
  const htmlContent = useMemo(
    () => (content as { data: string })?.data || "",
    [content]
  );
  const title = useMemo(() => extractTitle(htmlContent), [htmlContent]);
  const strippedContent = useMemo(
    () => stripHtmlTags(htmlContent).trim() || "No additional text",
    [htmlContent]
  );

  return (
    <div
      className={cn(
        "p-5 cursor-pointer hover:bg-gray-50 transition duration-100 border-b-2 relative",
        selected
          ? "bg-gray-100 rounded-lg hover:bg-gray-100 border-transparent"
          : "border-b-2 border-gray-100"
      )}
      onClick={() => {
        handleSelectedCase(id);
      }}
    >
      {status === "processing" && (
        <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
          <div className="w-6 h-6 border-2 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
        </div>
      )}
      <div className="grid text-sm select-none">
        <h3 className="font-semibold text-black-500 border-b-grey-100 line-clamp-1">
          {title}
        </h3>
        <p className="text-black-300 line-clamp-2">{strippedContent}</p>
        <p className="text-gray-400 mt-2">
          {moment(updatedAt).format("MMM D, YYYY")}
        </p>
      </div>
    </div>
  );
}

export default React.memo(Case);
