import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
import Case from "./Case";
import { CaseStatusType, Case as CaseType } from "../../db/schema";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { TrashIcon } from "@heroicons/react/24/outline";

interface CasesProps {
  handleSelectedCase: (caseId: string) => void;
  setCaseContent?: (content: { data: string } | undefined) => void;
  newCase: CaseType | null;
  selectedCaseId: string;
  onCasesUpdate: (casesExist: boolean) => void;
  caseContent?: { data: string };
  isCaseLoading: boolean;
  page: string;
}

function Cases({
  handleSelectedCase,
  setCaseContent,
  newCase,
  selectedCaseId,
  onCasesUpdate,
  caseContent,
  isCaseLoading,
  page,
}: CasesProps) {
  const { user } = useAuth();
  const [cases, setCases] = useState<CaseType[]>([]);
  const isInitialLoad = useRef(true);
  const location = useLocation();
  const prevPageRef = useRef(page);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (caseContent && selectedCaseId && !isCaseLoading) {
      setCases((prevCases) =>
        prevCases.map((c) =>
          c.id === selectedCaseId ? { ...c, content: caseContent } : c
        )
      );
    }
  }, [caseContent, selectedCaseId, isCaseLoading]);

  const fetchCases = useCallback(
    async (userId?: string, limit = 10, offset = 0) => {
      let status;
      switch (page) {
        case "cases":
          status = [CaseStatusType.enum.active, CaseStatusType.enum.processing];
          break;
        case "trash":
          status = CaseStatusType.enum.discarded;
          break;
        default:
          status = "";
      }

      try {
        const response = await axios.get("/api/cases", {
          params: { userId, status, limit, offset },
          withCredentials: true,
        });
        return response.data?.cases;
      } catch (error) {
        console.error("Error fetching cases:", error);
        throw error;
      }
    },
    [page]
  );

  const loadCases = useCallback(async () => {
    try {
      const data = await fetchCases(user?.id);
      setCases(data);
      onCasesUpdate(data.length > 0);

      const isPageChange = prevPageRef.current !== page;
      prevPageRef.current = page;

      const isRootPath =
        location.pathname === "/cases" || location.pathname === "/trash";

      // Auto-select only if:
      // 1. It's initial load or new case or page change
      // 2. There are cases
      // 3. We're on root path
      // 4. Not on mobile
      if (
        (((isInitialLoad.current || newCase) &&
          data.length > 0 &&
          isRootPath) ||
          isPageChange) &&
        !isMobile
      ) {
        const newestCase = data[0];
        handleSelectedCase(newestCase.id);
        if (setCaseContent) {
          setCaseContent(newestCase.content as { data: string });
        }
        isInitialLoad.current = false;
      } else if (data.length === 0) {
        handleSelectedCase("");
        if (setCaseContent) {
          setCaseContent(undefined);
        }
      }
    } catch (error) {
      console.error("Cases.tsx error:", error);
    }
  }, [
    user?.id,
    handleSelectedCase,
    setCaseContent,
    fetchCases,
    newCase,
    onCasesUpdate,
    location.pathname,
    page,
    isMobile,
  ]);

  const handleDelete = async () => {
    if (!selectedCaseId) {
      console.error("No case selected for deletion");
      return;
    }

    try {
      const response = await axios.patch(
        `/api/cases/${selectedCaseId}/discard`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        console.log("Case discarded successfully:", response.data);

        // Find the index of the deleted case
        const deletedIndex = cases.findIndex((c) => c.id === selectedCaseId);

        // Update local state
        const updatedCases = cases.map((c) =>
          c.id === selectedCaseId
            ? { ...c, status: CaseStatusType.enum.discarded }
            : c
        );

        // Filter out discarded cases
        const activeCases = updatedCases.filter(
          (c) => c.status !== CaseStatusType.enum.discarded
        );

        setCases(activeCases);
        onCasesUpdate(activeCases.length > 0);

        // Select the case at the same index or the nearest available case
        if (activeCases.length > 0) {
          let newSelectedIndex = Math.min(deletedIndex, activeCases.length - 1);
          const newSelectedCase = activeCases[newSelectedIndex];
          handleSelectedCase(newSelectedCase.id);
          if (setCaseContent) {
            setCaseContent(newSelectedCase.content as { data: string });
          }
        } else {
          handleSelectedCase("");
          if (setCaseContent) {
            setCaseContent(undefined);
          }
        }

        // Reload the cases to ensure everything is up-to-date
        loadCases();
      }
    } catch (error) {
      console.error("Error discarding case:", error);
    }
  };

  useEffect(() => {
    loadCases();
  }, [loadCases]);

  // useEffect(() => {
  //   if (newCase) {
  //     loadCases();
  //   }
  // }, [newCase, loadCases]);

  useEffect(() => {
    onCasesUpdate(cases.length > 0);
  }, [cases, onCasesUpdate]);

  function renderCases() {
    return cases.map((singleCase) => (
      <Case
        key={singleCase.id}
        {...singleCase}
        status={singleCase.status}
        handleSelectedCase={handleSelectedCase}
        selected={singleCase.id === selectedCaseId}
      />
    ));
  }

  return (
    <div className="w-full h-screen flex flex-col pt-16 md:pt-6 p-6 pb-0">
      <h2 className="hidden md:block text-3xl font-semibold text-black-400 capitalize">
        {page}
      </h2>
      <div className="mt-6 md:mt-4 flex items-center justify-between">
        <p className="mx-auto pl-5 md:pl-0 md:mx-0 text-black-400 font-bold md:font-normal">
          {cases.length} cases
        </p>
        {page === "cases" && (
          <button
            className="btn pl-0 pr-0 border-0 min-h-0 h-auto focus-visible:border-0"
            disabled={cases.length === 0}
          >
            <TrashIcon
              className={`size-5 mr-1 text-blue-500 hover:text-blue-700 transition-all duration-300 ease-in-out ${
                cases.length > 0
                  ? "cursor-pointer"
                  : "cursor-not-allowed text-gray-400"
              }`}
              onClick={handleDelete}
            />
          </button>
        )}
      </div>
      <div className="mt-5 overflow-y-auto scrollbar-hide border-t-2 border-gray-100 md:border-t-0">
        {cases.length > 0 ? (
          renderCases()
        ) : (
          <p className="mt-4 md:mt-0 text-gray-500 text-center">
            No cases available
          </p>
        )}
      </div>
    </div>
  );
}

export default Cases;
