import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import cn from "classnames";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: "primary" | "secondary" | "clean" | "outline";
  size?: "xs" | "sm" | "md";
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      variant = "clean",
      size = "md",
      loading,
      ...props
    },
    ref
  ) => {
    function buttonStyleColors() {
      switch (variant) {
        case "primary":
          return "bg-blue-500 hover:bg-blue-600 text-white";
        case "secondary":
          return "bg-blue-50 text-blue-600 hover:bg-blue-100";
        case "outline":
          return "border-[1px] border-gray-300 shadow-none hover:bg-blue-50 hover:border-gray-300 hover:border-blue-200";
        case "clean":
          return "shadow-none";
        default:
          break;
      }
    }

    function buttonSizes() {
      switch (size) {
        case "xs":
          return "!min-h-7 h-[20px] px-2 rounded-lg text-xs";
        case "sm":
          return "!min-h-10 h-[25px]";
        case "md":
          return "";
        default:
          break;
      }
    }

    return (
      <button
        ref={ref}
        className={cn(
          "btn border-0 inline-flex items-center gap-1",
          buttonStyleColors(),
          buttonSizes(),
          className,
          {
            "bg-gray-400 text-gray-200 pointer-events-none": disabled,
          }
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
