import { useState, useCallback, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Menu from "./Menu/Menu";
import {
  CaseScore,
  CaseStatus,
  CaseStatusType,
  Case as CaseType,
  Suggestion,
} from "../db/schema";
import CasesLayout from "./CasesLayout";
import { Toaster } from "react-hot-toast";
import { toastOptions } from "./common/toastOptions";

function Dashboard() {
  const [newCase, setNewCase] = useState<CaseType | null>(null);
  const [selectedCaseId, setSelectedCaseId] = useState<string>("");
  const [caseContent, setCaseContent] = useState<{ data: string } | undefined>(
    undefined
  );
  const [hasCases, setHasCases] = useState<boolean>(true);
  const [caseScore, setCaseScore] = useState<CaseScore | undefined>(undefined);
  const [caseSuggestions, setCaseSuggestions] = useState<
    Suggestion[] | undefined
  >([]);
  const [caseAudioUrl, setCaseAudioUrl] = useState<string>("");
  const [isMobileCase, setIsMobileCase] = useState(false);
  const [caseStatus, setCaseStatus] = useState<
    (typeof CaseStatus.enumValues)[number]
  >(CaseStatusType.enum.active);

  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.name || !user?.department || !user?.badgeID) {
      window.location.href = "/post-signup";
    }
  }, [user]);

  const handleNewCase = useCallback(
    (
      createdCase: CaseType,
      score: CaseScore | undefined = undefined,
      suggestions: Suggestion[] = []
    ) => {
      setNewCase(createdCase);
      setSelectedCaseId(createdCase.id);
      setCaseContent(createdCase.content as { data: string });
      setHasCases(true);
      setCaseScore(score);
      setCaseSuggestions(suggestions);
      setCaseAudioUrl(createdCase.audioUrl || "");
      setCaseStatus(createdCase.status || CaseStatusType.enum.processing);
    },
    []
  );

  const renderContent = () => {
    if (path === "cases" || path === "trash") {
      return (
        <CasesLayout
          selectedCaseId={selectedCaseId}
          setSelectedCaseId={setSelectedCaseId}
          caseContent={caseContent}
          setCaseContent={setCaseContent}
          hasCases={hasCases}
          setHasCases={setHasCases}
          caseScore={caseScore}
          setCaseScore={setCaseScore}
          caseSuggestions={caseSuggestions}
          setCaseSuggestions={setCaseSuggestions}
          newCase={newCase}
          setCaseAudioUrl={setCaseAudioUrl}
          caseAudioUrl={caseAudioUrl}
          isMobileCase={isMobileCase}
          setIsMobileCase={setIsMobileCase}
          caseStatus={caseStatus}
          setCaseStatus={setCaseStatus}
        />
      );
    }
    return <Outlet />;
  };

  return (
    <>
      <div className="flex h-screen bg-gray-100">
        <Menu
          onNewCase={handleNewCase}
          isMobileCase={isMobileCase}
          setIsMobileCase={setIsMobileCase}
        />
        {renderContent()}
      </div>
      <Toaster position="bottom-center" toastOptions={toastOptions} />
    </>
  );
}

export default Dashboard;
