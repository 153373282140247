import { ReactNode, useRef } from "react";
import cn from "classnames";
import TooltipIcon from "../common/TooltipIcon";
import Button from "../common/Button";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface DropdownProps {
  label: string;
  variant?: "sm" | "md";
  description: string | ReactNode;
  id?: string;
  options: Array<{ value: string; label: string }>;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export default function Dropdown({
  className,
  label,
  variant = "md",
  description,
  id,
  options,
  value,
  onChange,
  ...props
}: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    // Remove focus to close the dropdown
    if (dropdownRef.current) {
      (
        dropdownRef.current.querySelector(".dropdown-content") as HTMLElement
      )?.blur();
    }
  };

  return (
    <fieldset className="form-control w-full">
      <div className="label p-0 min-h-10">
        <span className="flex items-center">
          <span className="text-gray-800 font-medium text-xs md:text-base">
            {label}
          </span>
          <TooltipIcon description={description} />
        </span>
        <div className="dropdown dropdown-end" ref={dropdownRef}>
          <Button
            variant="outline"
            size="sm"
            tabIndex={0}
            className="animate-none text-xs md:text-sm px-1 md:px-4 max-w-32 md:max-w-fit w-full"
            onClick={() => {
              const content = dropdownRef.current?.querySelector(
                ".dropdown-content"
              ) as HTMLElement;
              if (content?.matches(":focus-within")) {
                content.blur();
              }
            }}
          >
            <span className="truncate flex-1">
              {options.find((opt) => opt.value === value)?.label ||
                "Select option"}
            </span>
            <ChevronDownIcon className="size-[18px] flex-shrink-0" />
          </Button>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow m-0 mt-1"
          >
            {options.map((option) => (
              <li className="m-0 p-0 hover:cursor-pointer" key={option.value}>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    handleOptionClick(option.value);
                  }}
                  className={cn(
                    "hover:bg-gray-100 m-0 font-medium",
                    option.value === value && "bg-gray-200 hover:bg-gray-200"
                  )}
                >
                  {option.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </fieldset>
  );
}
