import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import Input from "../common/Input";
import Button from "../common/Button";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

// Add sanitization utilities
const sanitizeInput = (value: string): string => {
  return DOMPurify.sanitize(value);
};

interface SettingProps {
  field: string;
  value?: string | null;
  buttonText: string;
  type: "name" | "email" | "badgeId" | "password" | "department";
}

const Setting = ({ field, value, buttonText, type }: SettingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value as string);
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useAuth();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const sanitizedValue = sanitizeInput(inputValue);
      const { data } = await axios.post("/api/settings/account", {
        type,
        value: sanitizedValue,
      });

      setUser(data.user);
      toast.success(`${field} updated successfully`);
      setIsModalOpen(false);
      setInputValue("");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        const errorMessage = error.response?.data?.error || "An error occurred";
        toast.error(errorMessage);
      } else {
        // Handle non-Axios errors
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <div className="flex flex-col">
          <p className="m-0 font-bold text-sm">{field}</p>
          <p className="m-0 text-gray-500 text-sm">{value}</p>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsModalOpen(true)}
        >
          {buttonText}
        </Button>
      </div>

      {/* Modal */}
      <dialog
        id={`modal-${type}`}
        className={`modal ${isModalOpen ? "modal-open" : ""}`}
      >
        <div className="modal-box max-w-96">
          <h3 className="font-bold text-lg mt-2">Update {field}</h3>
          <div className="py-1">
            <Input
              type={type === "password" ? "password" : "text"}
              value={type === "password" ? "" : inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Enter new ${field.toLowerCase()}`}
              className="!w-full !max-w-full"
            />
          </div>
          <div className="modal-action">
            <Button
              variant="outline"
              onClick={() => {
                setIsModalOpen(false);
                setInputValue("");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              variant="primary"
            >
              {isLoading ? "Updating..." : "Save"}
            </Button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => setIsModalOpen(false)}>close</button>
        </form>
      </dialog>
    </>
  );
};

const Settings = () => {
  const [activeComponent, setActiveComponent] = useState<"account">("account");
  const { user, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const SettingsNav = () => (
    <div className="flex overflow-x-auto top-0 space-x-1 pb-4">
      <Button
        variant={activeComponent === "account" ? "secondary" : "clean"}
        size="sm"
        onClick={() => setActiveComponent("account")}
        className="animate-none"
      >
        Account
      </Button>
    </div>
  );

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await axios.post("/api/logout");
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      setIsLoggingOut(false);
    }
  };

  function Account() {
    return (
      <>
        <h2 className="mt-2 border-b-2 pb-2">Account</h2>
        <Setting
          field="Full Name"
          value={user?.name}
          buttonText="Change Name"
          type="name"
        />
        <Setting
          field="Email"
          value={user?.email}
          buttonText="Change Email"
          type="email"
        />
        <Setting
          field="Badge"
          value={user?.badgeID}
          buttonText="Change Badge #"
          type="badgeId"
        />
        <Setting
          field="Password"
          value="Set a permanent password to login to your account."
          buttonText="Change Password"
          type="password"
        />
        <Setting
          field="Department"
          value={user?.department}
          buttonText="Change Department"
          type="department"
        />
        <div className="flex justify-end mt-4">
          <Button
            onClick={handleLogout}
            disabled={isLoggingOut}
            variant="primary"
          >
            Logout
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="flex-1 p-6 flex flex-col w-full prose items-center overflow-y-auto mt-8 md:mt-0">
      <div className="max-w-5xl mt-8 w-full">
        <h1 className="text-4xl font-semibold text-black-400 capitalize w-full text-center">
          Settings
        </h1>
        {/* <SettingsNav /> */}
        {activeComponent === "account" && <Account />}
      </div>
    </div>
  );
};

export default Settings;
