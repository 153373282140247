import { SparklesIcon } from "@heroicons/react/24/outline";
import { GaugeComponent } from "react-gauge-component";
import Button from "../common/Button";
import { CaseScore, Suggestion } from "../../db/schema";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastOptions } from "../common/toastOptions";
import cn from "classnames";
import { useAuth } from "../../context/AuthContext";
import "react-h5-audio-player/lib/styles.css";

interface SuggestionsProps {
  caseSuggestions?: Suggestion[];
  caseScore?: CaseScore | null;
  caseId?: string;
  editorContent?: string;
}

function Suggestions({
  caseSuggestions,
  caseScore,
  caseId,
  editorContent,
}: SuggestionsProps) {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [score, setScore] = useState<CaseScore | null | undefined>(undefined);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const hasAlreadyBeenEvaluated = score !== undefined && suggestions.length > 0;
  const { user, decrementTrialLogs } = useAuth();

  useEffect(() => {
    setSuggestions(caseSuggestions || []);
  }, [caseSuggestions]);

  useEffect(() => {
    setScore(caseScore);
  }, [caseScore]);

  const handleToggleImplemented = async (suggestionId: string) => {
    try {
      const response = await axios.patch(
        `/api/suggestions/${suggestionId}`,
        {},
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuggestions((prevSuggestions) =>
          prevSuggestions.map((suggestion) =>
            suggestion.id === suggestionId
              ? {
                  ...suggestion,
                  implemented: response.data.suggestion.implemented,
                }
              : suggestion
          )
        );
      }
    } catch (error) {
      console.error("Error toggling suggestion implementation:", error);
    }
  };

  const handleEvaluate = async () => {
    if (!editorContent) return;

    setIsEvaluating(true);
    const toastId = toast.loading(
      `${hasAlreadyBeenEvaluated ? "Re-evaluating" : "Evaluating"} report...`,
      {
        duration: Infinity,
      }
    );

    try {
      const response = await axios.post(
        "/api/evaluate",
        {
          caseId,
          content: editorContent,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.suggestions) {
        console.log("response.data.suggestions");
        console.log(response.data.suggestions);
        setSuggestions(response.data.suggestions);
      }

      if (response.data.score) {
        setScore(response.data.score);
      }

      // Optimistically update the trial logs count
      if (user?.subscription_status === "trialing") {
        decrementTrialLogs();
      }

      toast.success(
        `Report ${
          hasAlreadyBeenEvaluated ? "re-evaluated" : "evaluated"
        } successfully`,
        {
          id: toastId,
          ...toastOptions,
        }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        const errorMessage =
          error.response?.data?.error || "Error refetching suggestions";
        toast.error(errorMessage, {
          id: toastId,
        });
      } else {
        // Handle non-Axios errors
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsEvaluating(false);
    }
  };

  return (
    <>
      <div className={cn(isEvaluating && "opacity-35 pointer-events-none")}>
        <div className="overflow-y-auto max-h-[calc(100vh-16rem)] md:max-h-max mb-4 md:mb-0 pt-2 md:pt-0">
          <div className="flex items-center prose mb-2">
            <SparklesIcon className="size-5 text-blue-500" />
            <h3 className="m-0 ml-2 text-base">Suggestions</h3>
          </div>
          {suggestions.length > 0 ? (
            suggestions.map(({ id, implemented, content }) => (
              <div className="form-control" key={id}>
                <label className="label cursor-pointer flex items-start justify-start">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-xs mt-1 peer border-blue-500 [--chkbg:theme(colors.blue.500)] [--chkfg:theme(colors.blue.50)] checked:border-blue-500"
                    checked={implemented || false}
                    onChange={() => handleToggleImplemented(id)}
                  />
                  <p className="mt-[1px] label-text ml-2 text-sm peer-checked:line-through peer-checked:text-gray-500 transition-colors duration-200 hover:text-blue-700">
                    {content}
                  </p>
                </label>
              </div>
            ))
          ) : (
            <p className="text-sm my-4">
              Get AI recommendations and a report score to improve your report.
            </p>
          )}
          {score !== undefined && (
            <div className="w-full overflow-hidden relative aspect-square prose">
              <h3 className="text-center mb-[-20px] mt-4">Report Score</h3>
              <div className="w-[135%] 3xl:w-[120%] absolute left-1/2 -translate-x-1/2">
                <GaugeComponent
                  style={{
                    width: "100%",
                  }}
                  id="gauge-component"
                  arc={{
                    subArcs: [
                      {
                        limit: 100,
                        color: "#0768fd",
                        showTick: true,
                      },
                    ],
                    emptyColor: "#cee1fe",
                    width: 0.35,
                  }}
                  value={score?.score ?? 0}
                  type="grafana"
                  pointer={{
                    animationDuration: 1500,
                  }}
                  labels={{
                    valueLabel: {
                      matchColorWithArc: true,
                      style: {
                        textShadow: "none",
                        fontWeight: "bold",
                      },
                    },
                    tickLabels: {
                      type: "outer",
                      defaultTickValueConfig: {
                        hide: true,
                      },
                      defaultTickLineConfig: {
                        hide: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
          <Button
            variant="primary"
            className="w-full mb-4 md:mb-0"
            onClick={handleEvaluate}
            loading={isEvaluating}
            disabled={isEvaluating || !editorContent}
          >
            {hasAlreadyBeenEvaluated ? "Re-evaluate Report" : "Evaluate Report"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Suggestions;
