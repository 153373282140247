import { useState, useEffect } from "react";
import Button from "./common/Button";
import Input from "./common/Input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { ReactComponent as Logo } from "../police-narratives-ai.svg";
import bgImg from "../pnai-bg.jpg";
import Loading from "./common/Loading";
import { toast } from "react-hot-toast";
import DOMPurify from "dompurify";
import TooltipIcon from "./common/TooltipIcon";

const sanitizeInput = (value: string): string => {
  return DOMPurify.sanitize(value);
};

function PostSignup() {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    badgeID: "",
    department: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name ?? "",
        badgeID: user.badgeID ?? "",
        department: user.department ?? "",
      });
    }
  }, [user]);

  const handleInputChange = (field: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateForm = () => {
    let newErrors: {
      name?: string;
      badgeID?: string;
      department?: string;
    } = {};

    // Name validation
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s'-]+$/.test(formData.name)) {
      newErrors.name = "Name is invalid";
    }

    // Badge validation
    if (!formData.badgeID) {
      newErrors.badgeID = "Badge ID is required";
    } else if (
      !/^[A-Za-z0-9]{1,4}[-\s]?[A-Za-z0-9]{3,6}$/.test(formData.badgeID)
    ) {
      newErrors.badgeID = "Badge ID is invalid";
    }

    // Department validation
    if (!formData.department) {
      newErrors.department = "Department is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        // Update each field separately using the settings API
        const fields = ["name", "badgeId", "department"] as const;
        for (const field of fields) {
          const value = sanitizeInput(
            formData[field === "badgeId" ? "badgeID" : field]
          );

          const { data } = await axios.post("/api/settings/account", {
            type: field,
            value,
          });
          setUser(data.user);
        }

        toast.success("Profile updated successfully");
        navigate("/cases");
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorMessage =
            error.response?.data?.error || "An error occurred";
          toast.error(errorMessage);
        } else {
          toast.error("An unexpected error occurred");
        }
        setLoading(false);
        setErrorState(true);
      }
    } else {
      setErrorState(true);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          <h1 className="text-3xl mb-2 font-semibold">Complete Your Profile</h1>
          <p className="mb-3 text-xs text-gray-500">
            This information is private and only used for reports.
          </p>

          <div className="space-y-4">
            <div>
              <p className="font-semibold pb-1 text-sm">Name</p>
              <Input
                type="text"
                value={formData.name}
                placeholder="John Doe"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  handleInputChange("name", e.target.value);
                }}
                className={`w-full ${
                  errorState && errors.name && "border-red-500"
                }`}
              />
              {errors.name && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.name}</p>
              )}
            </div>

            <div>
              <p className="font-semibold pb-1 text-sm">Badge ID</p>
              <Input
                type="text"
                value={formData.badgeID}
                placeholder="1314"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  handleInputChange("badgeID", e.target.value);
                }}
                className={`w-full ${
                  errorState && errors.badgeID && "border-red-500"
                }`}
              />
              {errors.badgeID && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.badgeID}</p>
              )}
            </div>

            <div>
              <p className="font-semibold pb-1 text-sm">Department</p>
              <Input
                type="text"
                value={formData.department}
                placeholder="LAPD"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  handleInputChange("department", e.target.value);
                }}
                className={`w-full ${
                  errorState && errors.department && "border-red-500"
                }`}
              />
              {errors.department && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.department}</p>
              )}
            </div>

            <Button className="w-full" variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostSignup;
