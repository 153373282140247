import { useRef, memo, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import toast from "react-hot-toast";
import Button from "../common/Button";
import MenuItem from "./MenuItem";
import {
  DocumentPlusIcon,
  MicrophoneIcon,
  ArrowUpTrayIcon,
  DocumentIcon,
  AdjustmentsHorizontalIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { Case, CaseScore, CaseStatusType, Suggestion } from "../../db/schema";
import AudioPlayback from "./AudioPlayback";
import { ReactComponent as Logo } from "../../police-narratives-ai.svg";
import badgeSrc from "../../police-narratives-badge.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

type OnNewCase = (
  newCase: Case,
  score?: CaseScore | undefined,
  suggestions?: Suggestion[]
) => void;

interface MenuProps {
  onNewCase: OnNewCase;
  isMobileCase: boolean;
  setIsMobileCase: (value: boolean) => void;
}

// Add this utility function before the Menu component
async function convertAudioToMono(file: File | Blob): Promise<Blob> {
  const audioContext = new AudioContext({ sampleRate: 16000 });
  const arrayBuffer = await file.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Create offline context for processing
  const offlineContext = new OfflineAudioContext(1, audioBuffer.length, 16000);
  const source = offlineContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(offlineContext.destination);
  source.start();

  // Render audio
  const renderedBuffer = await offlineContext.startRendering();

  // Convert to WAV format
  const length = renderedBuffer.length * 2;
  const buffer = new ArrayBuffer(44 + length);
  const view = new DataView(buffer);

  // WAV header
  const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  writeString(view, 0, "RIFF");
  view.setUint32(4, 36 + length, true);
  writeString(view, 8, "WAVE");
  writeString(view, 12, "fmt ");
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, 1, true);
  view.setUint32(24, 16000, true);
  view.setUint32(28, 32000, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, "data");
  view.setUint32(40, length, true);

  // Write audio data
  const data = new Float32Array(renderedBuffer.getChannelData(0));
  let offset = 44;
  for (let i = 0; i < data.length; i++) {
    const sample = Math.max(-1, Math.min(1, data[i]));
    view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
    offset += 2;
  }

  return new Blob([buffer], { type: "audio/wav" });
}

// Move this to a shared utility file later if needed
export const pollProcessingStatus = async (
  caseId: string,
  {
    onSuccess,
    onError,
    interval = 2000,
    timeout = 30 * 60 * 1000, // 30 minutes
  }: {
    onSuccess: (data: any) => void;
    onError?: (error: string) => void;
    interval?: number;
    timeout?: number;
  }
) => {
  const endTime = Date.now() + timeout;

  const poll = setInterval(async () => {
    try {
      const response = await axios.get(
        `/api/cases/${caseId}/processing-status`
      );

      if (response.data.status === "active") {
        clearInterval(poll);
        // Fetch complete case data
        const caseResponse = await axios.get(`/api/cases/${caseId}`);
        toast.success("Case created", { duration: 5000 });
        onSuccess(caseResponse.data);
      } else if (response.data.status === "error") {
        clearInterval(poll);
        const errorMsg = "Error processing audio: " + response.data.error;
        toast.error(errorMsg);
        onError?.(errorMsg);
      }

      // Stop polling if timeout reached
      if (Date.now() > endTime) {
        clearInterval(poll);
        const timeoutMsg = "Processing timed out";
        toast.error(timeoutMsg);
        onError?.(timeoutMsg);
      }
    } catch (error) {
      console.error("Error polling status:", error);
      clearInterval(poll);
      onError?.("Error checking processing status");
    }
  }, interval);

  // Return cleanup function
  return () => clearInterval(poll);
};

const Menu = memo(function ({
  onNewCase,
  isMobileCase,
  setIsMobileCase,
}: MenuProps) {
  const { user, decrementTrialLogs } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDialogElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Loading states
  // const [uploading, setUploading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [audioUploading, setAudioUploading] = useState(false);
  // const [audioRecord, setAudioRecording] = useState(false);

  // const handleAvatarClick = () => {
  //   fileInputRef.current?.click();
  // };

  // Avatar upload
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event);
    // const file = event.target.files?.[0];
    // if (!file) return;

    // try {
    //   const data = await s3Client.send(
    //     new PutObjectCommand({
    //       Body: file,
    //       Bucket: process.env.BUCKET,
    //       Key: `${user?.id}-${file.name}`,
    //     })
    //   );
    //   console.log("File uploaded successfully", data);
    // } catch (err) {
    //   console.error("Error uploading file:", err);
    // }
  };

  const handleNewCase = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.post(
        "/api/cases",
        {
          caseNumber: ``,
          incidentDate: new Date().toISOString().split("T")[0],
          incidentTime: new Date().toISOString().split("T")[1].split("Z")[0],
          status: CaseStatusType.enum.active,
          content: {
            time: Date.now(),
            blocks: [
              {
                type: "header",
                data: {
                  text: "New case",
                },
              },
            ],
            version: "2.22.2",
          },
        },
        { withCredentials: true }
      );

      if (response.status === 201) {
        onNewCase(response.data.case, undefined, []);
        console.log("New case created:", response.data.case);
      } else {
        console.error("Failed to create new case");
      }
    } catch (error) {
      console.error("Error creating new case:", error);
    } finally {
      const elem = document.activeElement;
      if (elem && "blur" in elem) {
        (elem as HTMLElement).blur();
      }
      // setIsLoading(false);
    }
  };

  const handleOnRecordAudioClick = () => {
    // setAudioRecording(true);
    modalRef.current?.showModal();
  };

  const handleOnUploadAudioClick = () => {
    audioInputRef.current?.click();
  };

  const handleAudioFileChange = async (
    fileOrEvent: File | Blob | React.ChangeEvent<HTMLInputElement>
  ) => {
    let file: File | Blob;

    if (fileOrEvent instanceof File || fileOrEvent instanceof Blob) {
      file = fileOrEvent;
    } else if (fileOrEvent.target && fileOrEvent.target.files) {
      file = fileOrEvent.target.files[0];
    } else {
      console.error("Invalid input: expected File, Blob, or file input event");
      return;
    }

    if (!file) return;

    const validTypes = [
      "audio/mpeg",
      "audio/mp4",
      "audio/x-m4a",
      "audio/wav",
      "audio/aac",
      "audio/aiff",
    ];
    if (!validTypes.includes(file.type)) {
      toast.error(
        "Please upload a valid audio file (MP3, M4A, WAV, AAC, or AIFF)"
      );
      return;
    }

    const toastId = toast.loading("Preparing to upload audio...");

    try {
      // Convert and upload audio
      const convertedAudio = await convertAudioToMono(file);
      const urlResponse = await axios.post("/api/get-upload-url", {
        userId: user?.id,
        fileName:
          file instanceof File
            ? file.name.replace(/\.[^/.]+$/, ".wav")
            : `recorded_audio_${user?.id}_${moment().format("DD-MM-YYYY")}.wav`,
      });

      const { uploadURL, key } = urlResponse.data;

      // Create pending case first
      const pendingCase = await axios.post("/api/cases", {
        caseNumber: `case-${Date.now()}`,
        incidentDate: new Date().toISOString().split("T")[0],
        incidentTime: new Date().toISOString().split("T")[1].split("Z")[0],
        status: "processing",
        content: { data: "Processing audio..." },
      });

      // Upload audio file
      await axios.put(uploadURL, convertedAudio, {
        headers: { "Content-Type": "audio/wav" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
              (progressEvent.total || convertedAudio.size)
          );
          toast.loading(`Uploading audio: ${percentCompleted}%`, {
            id: toastId,
          });
        },
      });

      // Start processing
      await axios.post("/api/transcribe", {
        key,
        caseId: pendingCase.data.case.id,
      });

      // Show success message and create case
      toast.loading("Audio uploaded successfully. Processing audio..", {
        id: toastId,
        duration: 5000,
      });

      onNewCase({
        ...pendingCase.data.case,
        status: CaseStatusType.enum.processing,
      });

      // Start polling for status updates
      pollProcessingStatus(pendingCase.data.case.id, {
        onSuccess: (data) => {
          onNewCase(data.case, data.score, data.suggestions);
        },
        onError: (error) => {
          console.error("Processing failed:", error);
        },
      });

      // Optimistically update the trial logs count
      if (user?.subscription_status === "trialing") {
        decrementTrialLogs();
      }
    } catch (error) {
      console.error("Error processing audio file:", error);
      toast.error("Error processing audio file", { id: toastId });
    }
  };

  const loadAvatar = () => {
    if (user?.avatarUrl) {
      return <img src={user.avatarUrl} alt="" />;
    } else if (user?.name) {
      return (
        <div className="text-white font-semibold text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {`${user.name.split(" ")[0][0]}${
            user.name.split(" ")[user.name.split(" ").length - 1]?.[0] || ""
          }`}
        </div>
      );
    }
  };

  const handleBackButton = () => {
    setIsMobileCase(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      {/* Mobile Menu Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 h-16 bg-gray-50 border-b-2 z-50 flex items-center px-4 justify-between">
        <button
          onClick={() =>
            isMobileCase
              ? handleBackButton()
              : setIsMobileMenuOpen(!isMobileMenuOpen)
          }
          className="p-2"
        >
          {isMobileCase ? (
            <ArrowLeftIcon className="h-6 w-6" />
          ) : isMobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        <div className="mb-4 block md:hidden">
          <img
            src={badgeSrc}
            className="w-8 mt-5"
            alt="Police Narratives AI Badge"
          />
        </div>
      </div>

      {/* Main Menu Container */}
      <div
        className={cn(
          "w-72 md:w-64 bg-gray-50 border-r-2 flex flex-col h-screen transition-all duration-300",
          "fixed md:static top-0 left-0 z-40",
          "md:translate-x-0",
          isMobileCase
            ? "-translate-x-full"
            : isMobileMenuOpen
            ? "translate-x-0"
            : "-translate-x-full",
          "pt-16 md:pt-0" // Add padding top on mobile for header
        )}
      >
        <AudioPlayback
          modalRef={modalRef}
          handleAudioFileChange={handleAudioFileChange}
        />
        <div className="p-6">
          <div className="mb-4">
            <Logo className="w-full h-auto" />
          </div>
          <div className="grid gap-y-2">
            {/* <Input
                type="search"
                id="search"
                // value={email}
                placeholder={`Search`}
                // onChange={(e) => {
                //   errorState && setErrorState(false);
                //   setEmail(e.target.value);
                // }}
                className={`input w-full input-ghost mt-2`}
              /> */}
            <div className="grid grid-cols-10 gap-x-2">
              <div className="col-span-7 dropdown">
                <Button
                  tabIndex={0}
                  className="btn-primary w-full"
                  variant="primary"
                >
                  <div className="flex items-center pl-2">
                    <DocumentPlusIcon className="size-6 mr-1" />
                    <p>New Case</p>
                  </div>
                </Button>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow mt-2"
                >
                  <li>
                    <p
                      onClick={handleOnRecordAudioClick}
                      className="hover:bg-gray-100"
                    >
                      <MicrophoneIcon className="size-4" />
                      Record Audio
                    </p>
                  </li>
                  <li onClick={handleOnUploadAudioClick}>
                    <p className="hover:bg-gray-100">
                      <ArrowUpTrayIcon className="size-4" />
                      Upload Audio
                    </p>
                  </li>
                  <li onClick={handleNewCase}>
                    <p className="hover:bg-gray-100">
                      <DocumentIcon className="size-4" />
                      Blank Report
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-span-3">
                <Button
                  className="btn btn-active border-0 w-full"
                  tabIndex={0}
                  variant="secondary"
                  onClick={() => {
                    navigate("/preferences");
                    setIsMobileMenuOpen(false);
                  }}
                >
                  <AdjustmentsHorizontalIcon className="size-6 mr-1" />
                </Button>
              </div>
            </div>
          </div>
          <input
            type="file"
            ref={audioInputRef}
            onChange={handleAudioFileChange}
            accept="audio/*,.m4a,.mp3,.wav,.aac,.alac,.aiff"
            className="hidden"
          />
          <div className="grid gap-y-1 mt-6">
            <MenuItem
              page="cases"
              onNavigate={() => setIsMobileMenuOpen(false)}
            >
              <p>Cases</p>
            </MenuItem>
            <MenuItem
              page="trash"
              onNavigate={() => setIsMobileMenuOpen(false)}
            >
              <p>Trash</p>
            </MenuItem>
          </div>
        </div>
        {/* User Badge */}
        <div
          className="border-t px-4 py-2 mt-auto hover:cursor-pointer group" // Add group class here
          onClick={() => {
            navigate("/settings");
            setIsMobileMenuOpen(false);
          }}
        >
          <div className="grid grid-cols-12 gap-x-3 items-center">
            <div
              className="avatar col-span-3 cursor-pointer flex"
              // onClick={handleAvatarClick}
            >
              <div className="rounded-full overflow-hidden relative bg-blue-500 flex items-center justify-center aspect-square w-full min-w-[2.5rem] min-h-[2.5rem]">
                {loadAvatar()}
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              className="hidden"
            />
            <div className="text col-span-7 flex items-center">
              <div className="flex flex-col justify-between h-full w-full py-2">
                <p className="font-bold text-sm h-auto">
                  {(user?.name || "Name here").split(" ").map((word, i) => (
                    <>
                      <span
                        key={i}
                        className="h-auto inline-block max-w-[140px] truncate leading-none mb-[-2px]"
                      >
                        {word}
                      </span>
                      {i < 1 && " "}
                    </>
                  ))}
                </p>
                <p className="text-gray-500 text-sm">
                  {user?.badgeID ? `Badge #${user?.badgeID}` : `Set Badge #`}
                </p>
              </div>
            </div>
            <div className="col-span-2 flex items-center">
              <Cog8ToothIcon
                className={cn(
                  "size-6 text-gray-500 opacity-50 group-hover:opacity-80 transition-opacity",
                  location.pathname.startsWith("/settings") &&
                    "!text-blue-500 !opacity-100 transition-opacity"
                )}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay - Always mounted */}
      <div
        className={cn(
          "md:hidden fixed inset-0 bg-black-500/35 backdrop-blur-sm z-30",
          "transition-all duration-300 ease-in-out",
          isMobileMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        )}
        onClick={() => setIsMobileMenuOpen(false)}
      />
    </>
  );
});

export default Menu;
