import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import Cases from "./Cases/Cases";
import Editor from "./Editor";
import axios from "axios";
import {
  CaseScore,
  Case as CaseType,
  Suggestion,
  CaseStatus,
  CaseStatusType,
} from "../db/schema";
import { pollProcessingStatus } from "./Menu/Menu";
import cn from "classnames";

interface CasesLayoutProps {
  setSelectedCaseId: React.Dispatch<React.SetStateAction<string>>;
  setCaseContent: React.Dispatch<
    React.SetStateAction<{ data: string } | undefined>
  >;
  setHasCases: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseScore: React.Dispatch<React.SetStateAction<CaseScore | undefined>>;
  setCaseSuggestions: React.Dispatch<
    React.SetStateAction<Suggestion[] | undefined>
  >;
  setCaseAudioUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  caseSuggestions: Suggestion[] | undefined;
  newCase: CaseType | null;
  selectedCaseId: string;
  caseContent: { data: string } | undefined;
  hasCases: boolean;
  caseScore: CaseScore | undefined;
  caseAudioUrl?: string;
  isMobileCase: boolean;
  setIsMobileCase: (value: boolean) => void;
  caseStatus: (typeof CaseStatus.enumValues)[number];
  setCaseStatus: (status: (typeof CaseStatus.enumValues)[number]) => void;
}

function CasesLayout({
  setSelectedCaseId,
  setCaseContent,
  setHasCases,
  setCaseScore,
  setCaseSuggestions,
  setCaseAudioUrl,
  caseSuggestions,
  newCase,
  selectedCaseId,
  caseContent,
  hasCases,
  caseScore,
  caseAudioUrl,
  isMobileCase,
  setIsMobileCase,
  caseStatus,
  setCaseStatus,
}: CasesLayoutProps) {
  const { id: route_id } = useParams<{ id: string }>();
  const DEBOUNCE_TIME = 500;
  const navigate = useNavigate();
  const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const isInitialMount = useRef(true);

  const handleSelectedCase = useCallback(
    async (caseId: string) => {
      if (!caseId) {
        setSelectedCaseId("");
        setCaseContent(undefined);
        setCaseAudioUrl("");
        return;
      }
      setIsCaseLoading(true);
      setSelectedCaseId(caseId);
      try {
        const response = await axios.get(`/api/cases/${caseId}`, {
          withCredentials: true,
        });
        console.log("handleSelectedCase");
        setCaseContent(response.data.case.content);
        setCaseSuggestions(response.data.suggestions);
        setCaseScore(response.data.score);
        setCaseAudioUrl(response.data.case.audioUrl);
        setCaseStatus(response.data.case.status);
        navigate(`/${page}/${caseId}`);
      } catch (error) {
        console.error("Error fetching case:", error);
      } finally {
        setIsCaseLoading(false);
      }
    },
    [
      navigate,
      page,
      setCaseContent,
      setCaseScore,
      setCaseSuggestions,
      setSelectedCaseId,
      setCaseStatus,
    ]
  );

  const handleEditorChange = useMemo(
    () =>
      debounce(async ({ content, caseId }) => {
        if (
          caseId &&
          content.data !== "<h1></h1>" &&
          content.data !== caseContent?.data
        ) {
          try {
            const response = await axios.patch(
              `/api/cases/${caseId}`,
              { content },
              {
                withCredentials: true,
              }
            );
            if (response.status === 200) {
              console.log("Case content updated successfully");
              setCaseContent(content);
            }
          } catch (error) {
            console.error("Error updating case content:", error);
          }
        }
      }, DEBOUNCE_TIME),
    [caseContent, setCaseContent]
  );

  const editorChangeHandler = useCallback(
    ({ content, caseId }) => {
      console.log("editorChangeHandler");
      if (content.data !== caseContent?.data) {
        handleEditorChange({ content, caseId });
      }
    },
    [handleEditorChange, caseContent]
  );

  const setInitialCaseContentOnPageLoad = useCallback(
    async (caseId: string) => {
      setIsCaseLoading(true);
      try {
        const response = await axios.get(`/api/cases/${caseId}`, {
          withCredentials: true,
        });
        setCaseContent(response.data.case.content);
        setCaseSuggestions(response.data.suggestions);
        setCaseScore(response.data.score);
        setCaseAudioUrl(response.data.case.audioUrl || "");
        setCaseStatus(response.data.case.status);

        // Start polling if case is processing
        if (response.data.case.status === CaseStatusType.enum.processing) {
          pollProcessingStatus(caseId, {
            onSuccess: (caseData) => {
              setCaseContent(caseData.case.content);
              setCaseSuggestions(caseData.suggestions);
              setCaseScore(caseData.score);
              setCaseStatus(caseData.case.status);
            },
          });
        }
      } catch (error) {
        console.error("Error fetching case:", error);
        setCaseContent(undefined);
      } finally {
        setIsCaseLoading(false);
      }
    },
    [
      setCaseContent,
      setCaseScore,
      setCaseSuggestions,
      setCaseAudioUrl,
      setCaseStatus,
    ]
  );

  // Clean up the debounced function when component unmounts
  useEffect(() => {
    return () => {
      handleEditorChange.cancel();
    };
  }, [handleEditorChange]);

  const handleCasesUpdate = useCallback(
    (casesExist: boolean) => {
      setHasCases(casesExist);
      if (!casesExist) {
        setSelectedCaseId("");
        setCaseContent(undefined);
        setIsMobileCase(false); // Reset mobile case view when no cases exist
      }
    },
    [setCaseContent, setHasCases, setSelectedCaseId, setIsMobileCase]
  );

  // If route id is passed in, load that case
  useEffect(() => {
    if (isInitialMount.current && route_id) {
      setSelectedCaseId(route_id);
      setInitialCaseContentOnPageLoad(route_id);
      setIsMobileCase(true); // Only set to editor view when specific URL ID exists
      isInitialMount.current = false;
    }
  }, [
    route_id,
    setInitialCaseContentOnPageLoad,
    setSelectedCaseId,
    setIsMobileCase,
  ]);

  const lastNewCaseId = useRef<string | null>(null);

  // Select new case after creation
  useEffect(() => {
    if (newCase && newCase.id !== lastNewCaseId.current) {
      lastNewCaseId.current = newCase.id;
      setSelectedCaseId(newCase.id);
      setCaseContent(newCase.content as { data: string });
      navigate(`/${page}/${newCase.id}`);
      setIsMobileCase(true);
    }
  }, [
    newCase,
    setSelectedCaseId,
    setCaseContent,
    navigate,
    page,
    setIsMobileCase,
  ]);

  return (
    <>
      <div
        className={cn(
          "md:w-[22rem] bg-white border-r",
          "fixed md:static inset-0 z-30",
          "transition-transform duration-300",
          isMobileCase && "translate-x-0"
        )}
      >
        <Cases
          selectedCaseId={selectedCaseId}
          handleSelectedCase={(caseId) => {
            handleSelectedCase(caseId);
            if (caseId) {
              // Only set to mobile case view if there's a valid case ID
              setIsMobileCase(true);
            }
          }}
          setCaseContent={setCaseContent}
          newCase={newCase}
          onCasesUpdate={handleCasesUpdate}
          caseContent={caseContent}
          isCaseLoading={isCaseLoading}
          page={page}
        />
      </div>
      <div
        className={cn(
          "flex-1 flex flex-col overflow-auto",
          "fixed md:static inset-0 z-30 bg-white",
          "transition-transform duration-300",
          isMobileCase ? "translate-x-0" : "translate-x-full md:translate-x-0"
        )}
      >
        <Editor
          caseContent={caseContent}
          caseSuggestions={caseSuggestions}
          caseScore={caseScore}
          caseAudioUrl={caseAudioUrl}
          onChange={editorChangeHandler}
          caseId={selectedCaseId}
          hasCases={hasCases}
          page={page}
          isProcessing={caseStatus === CaseStatusType.enum.processing}
        />
      </div>
    </>
  );
}

export default CasesLayout;
