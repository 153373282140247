import { TextareaHTMLAttributes, ReactNode, useState } from "react";
import cn from "classnames";
import TooltipIcon from "../common/TooltipIcon";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  description: string | ReactNode;
  id?: string;
  placeholder?: string;
  maxLength?: number;
}

export default function TextArea({
  className,
  label,
  description,
  id,
  placeholder,
  maxLength,
  onChange,
  value = "",
  ...props
}: TextAreaProps) {
  const [charCount, setCharCount] = useState(String(value).length);
  const uniqueId = id || `textarea-${label.toLowerCase().replace(/\s+/g, "-")}`;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(e.target.value.length);
    onChange?.(e);
  };

  return (
    <fieldset className="form-control w-full mb-3">
      <div className="label p-0 min-h-10">
        <span className="flex items-center">
          <span className="text-gray-800 font-medium text-xs md:text-base">
            {label}
          </span>
          <TooltipIcon description={description} />
        </span>
      </div>
      <textarea
        id={uniqueId}
        className={cn(
          "textarea textarea-bordered w-full",
          "focus:outline-none focus:border-blue-500",
          "border-gray-400 bg-gray-50",
          "transition-colors",
          className
        )}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={handleChange}
        value={value}
        {...props}
      />
      {maxLength && (
        <div className="text-sm text-gray-500 text-right mt-1">
          {charCount}/{maxLength}
        </div>
      )}
    </fieldset>
  );
}
