import { useNavigate } from "react-router-dom";
import bgImg from "../pricing-bg.jpg";
import { useEffect } from "react";

function PurchaseConfirmation() {
  const navigate = useNavigate();

  // Get value from URL query params and parse as float
  const queryParams = new URLSearchParams(window.location.search);
  const rawValue = queryParams.get("value") || "0";
  const value = parseFloat(parseFloat(rawValue).toFixed(2));

  useEffect(() => {
    try {
      if (!value) {
        console.warn("No value parameter found in URL");
      }

      // Fire conversion tracking
      window.gtag("event", "conversion", {
        send_to: "AW-16789938159/sQubCKafvO0ZEO-_iMY-",
        value,
        currency: "USD",
      });
      const redirectTimer = setTimeout(() => {
        navigate("/cases");
      }, 5000);

      // Cleanup timer on unmount
      return () => clearTimeout(redirectTimer);
    } catch (error) {
      console.error("Error in conversion tracking:", error);
      // Still redirect even if tracking fails
      navigate("/cases");
    }
  }, [navigate, value]);

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-blue-50 prose bg-cover bg-center py-24"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="mb-4 text-center text-4xl md:text-5xl font-semibold text-gray-900">
          Your subscription is <span className="text-blue-500">confirmed</span>{" "}
          and <span className="text-blue-500">active</span>
        </h1>
        <p className="text-center mb-10 px-6 max-w-4xl mx-auto text-lg">
          You're about to save countless hours on report writing. Redirecting
          back to cases in 5 seconds...
        </p>
      </div>
      <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
  );
}

export default PurchaseConfirmation;
